.dashboard-main {
  min-height: calc(100vh - 75px);
}

.dashboard-banner {
  background-image: url("../../assets/images/dashboard-banner.png");
  background-size: cover;
  height: 380px;
  padding: 40px 100px;
  vertical-align: middle;
  display: table-cell;
  width: 100vw;
}

.dashboard-banner-title {
  color: white;
  font-size: 40px;
  margin-top: 0px;
}

.dashboard-banner-desc {
  color: white;
  font-size: 18px;
  /* color: #d5dbdb; */
  line-height: 1.5;
  letter-spacing: 1.5;
  background-color: rgba(0, 0, 0, 0.326);
}

.dashboard-banner-icon-container {
  text-align: right;
}

.dashboard-banner-icon {
  height: 120px;
}

.dashboard-highlight {
  padding: 40px 140px;
}

.dashboard-highlight-icon {
  height: 48px;
}

.dashboard-highlight-text-container {
  width: calc(100% - 66px);
  display: inline-block;
  padding-left: 20px;
}
.hightLight-items {
    display: flex;
    align-items: center;
}
.dashboard-highlight-title {
  font-weight: bold;
  margin-bottom: 8px;
}

.dasboard-highlight-desc {
  color: rgba(0, 0, 0, 0.54);
}

.dashboard-courses {
  padding: 0 100px;
}

.dashboard-courses-header {
  font-size: 36px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.dashboard-courses-header-decor {
  border-top: solid 3px #EC7211;
  width: 200px;
  margin-bottom: 30px;
}

.dashboard-courses-list-item {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 28, 36, 0.3);
  margin-bottom: 30px;
  border-radius: 5px;
  padding: 15px;
}

.dashboard-courses-list-item:hover {
  box-shadow: 0px 4px 8px rgba(0, 28, 36, 0.3);
}

.dashboard-courses-list-item-info {
  display: inline-block;
  width: calc(100% - 120px);
}

.dashboard-courses-list-item-title {
  font-size: 24px;
  margin-bottom: 12px;
}

.dashboard-courses-list-item-property {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  margin-top: 4px;
}

.dashboard-courses-list-item-property a {
  text-decoration: none;
  color: #0073bb;
}

.dashboard-courses-list-item-property-icon {
  margin-right: 10px;
}

.dashboard-courses-list-item-desc {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-top: 12px;
}

.dashboard-courses-list-item-separator {
  border-bottom: solid 1px #d5dbdb;
  margin: 20px -15px;
}

.dashboard-courses-list-item-action {
  text-align: end;
}

.dashboard-courses-list-item-thumbnail {
  display: inline-block;
  width: 120px;
  vertical-align: top;
}

.dashboard-courses-list-item-thumbnail img {
  max-width: 100%;
  border-radius: 5px;
}

.dashboard-loading-gif {
  height: 16px;
}

.btn-normal {
  border: none;
  padding: 5px 15px;
  border-radius: 20px;
  background-color: #EB5F07 ;
  border-color: #EB5F07;
  display: inline-block;
  position: relative;
  text-decoration: none;
  border-collapse: separate;
  border-spacing: 0;
  color: white;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  /* font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif); */
  cursor: pointer;
}

.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

@media only screen and (max-width: 768px) {
  .dashboard-banner {
    padding: 10px 20px;
    height: 200px;
  }
  .dashboard-banner-title {
    color: white;
    font-size: 24px;
    margin-top: 0px;
  }
  .dashboard-banner-desc{
    font-size: 14px;
    line-height: 1.2;
  }
  .dashboard-banner-icon-container img{
    height: 30px;
    width: 30px;
  }
  .dashboard-highlight {
    padding: 20px 30px;
  }
  .dashboard-highlight > div {
    display: block !important;
  }
  .dashboard-highlight > div > div {
    max-width: 100% !important;
  }
  .dashboard-highlight-icon {
    height: 25px;
  }
  .dashboard-highlight-title {
    font-size: 16px;
    line-height: 1.2;
  }
  .dashboard-highlight-desc {
    font-size: 14px;
    line-height: 1.2;
  }
  .dashboard-courses {
    padding: 20px 30px;
  }
  .dashboard-courses-header{
    font-size: 22px;
    margin-top: 10px;
  }
  
  .dashboard-courses-list-item-info {
    width: 100%;
  }

  .dashboard-courses-list-item-title {
    font-size: 20px;
    margin-bottom: 12px;
  }
  
  .dashboard-courses-list-item-property{
    font-size: 14px;
  }
  
  .dashboard-courses-list-item-property-icon {
    margin-right: 5px;
  }
  
  .dashboard-courses-list-item-desc {
    font-size: 14px;
  }
  
  .dashboard-courses-list-item-separator {
    border-bottom: solid 1px #d5dbdb;
    margin: 20px -15px;
  }
  
  .dashboard-courses-list-item-action {
    text-align: end;
  }
  
  .dashboard-courses-list-item-thumbnail {
    display: none;
    vertical-align: top;
    width: 100%;
    margin-top: 10px;
  }
  
  .dashboard-courses-list-item-thumbnail img {
    max-width: 50%;
    display: block;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
  }
}


@media only screen and (min-width: 768px) and (max-width: 992px) {
  .dashboard-banner {
    padding: 20px 40px;
    height: 300px;
  }

  .dashboard-banner-title {
    color: white;
    font-size: 36px;
    margin-top: 0px;
  }
  .dashboard-banner-desc{
    font-size: 17px;
    line-height: 1.2;
  }
  .dashboard-highlight > div {
    display: block !important;
  }
  .dashboard-highlight > div > div {
    max-width: 100% !important;
  }
  .dashboard-highlight-icon {
    height: 35px;
  }
  .dashboard-highlight-title {
    font-size: 16px;
    line-height: 1.2;
  }
  .dashboard-highlight-desc {
    font-size: 14px;
    line-height: 1.2;
  }
  .dashboard-courses {
    padding: 20px 30px;
  }
}